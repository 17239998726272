import React, { useEffect, useState } from "react";
import { BadgeCheck, BoxInfor, BoxMedia, BoxText, CardInfo, CardPlay, CardStar, Container, TitleName, } from "./styled";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
//import { getVideoTiktok } from "utils/functionsTiktok";
import { FaPlay } from "react-icons/fa";
import { FiCameraOff } from "react-icons/fi";
import { BiPlay } from "react-icons/bi";
import { HiBadgeCheck } from "react-icons/hi";
//import { getTranslation } from "utils/translations";
//import Image from "next/image";


const CardTiktokBK = (props) => {
  //const config = useSelector((state) => state.SliceConfig);
  const icon = {
    size: 20,
    colorActive: "config.data.config.star_color_primary",
    colorInative: "config.data.config.star_color_secondary",
  };



  const [isClicked, setIsClicked] = useState(false);
  const [ziggeoApi, setZiggeoApi] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  useEffect(() => {
    //if (typeof ziggeoApp !== 'undefined') { setZiggeoApi(ziggeoApp); }
  })
  const getThumbnail = (token) => {
    let response = ziggeoApi.videos.get(token);

    response.success(function (data) {
      //('thumb_imagem', data.original_stream.embed_image_url)
      setThumbnail(data.original_stream.embed_image_url);
    })
  }
  const getMedia = (media) => {
    if (media?.type == "tiktok") {
      //console.log("TIKTOK : ", media.url)
    }

    switch (media?.type) {
      case "image":
        return (
          <CardPlay id='abrir'>
            <img src={props.review?.media[0]?.url} id='abrir' width="150" height="150" />
          </CardPlay>
        )
      case "video":
        return (
          ziggeoApi && getThumbnail(media?.url),
          <CardPlay id='abrir'>
            {thumbnail && <img src={thumbnail} id='abrir' width="150" height="150" />}
            <FaPlay style={{ position: "absolute", }} color="#ffffff" size={45} id='abrir' />
          </CardPlay>
        )
      case "tiktok":
        return (
          <CardPlay id='abrir' tiktok={true}>
            <iframe
              src={`https://tiktok.com/embed/${media.url}`}
              style={{ border: "none", width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '16px', }}
              allowfullscreen
              scrolling="no"
              allow="encrypted-media;"
            />
          </CardPlay>
        )
      default:
        return (
          <CardPlay id='abrir'>
            <FiCameraOff onClick={() => {
              //singleReview(props.review?.id) 
            }} size={50} color={"gray"} id='abrir' />
          </CardPlay>
        )
    }
  }

  //const language = useSelector((state) => state.SliceLanguage);
  //const [translation, setTranslation] = useState(getTranslation("pt-br"))
 // useEffect(() => {
    //if (language.data != "pt-br") { setTranslation(getTranslation(language.data)) }
  //}, [language])

  const controlClick = () => {
    if (
      props.review?.media[0]?.type == "instagram" ||
      props.review?.media[0]?.type == "youtube" ||
      props.review?.media[0]?.type == "tiktok"
    ) {
      //singleReview(props.review?.id)
    }

  }
  return (
    <Container
      key={props.review?.id}
      onClick={(e) => controlClick()}
      mediaType={props.review?.media[0]?.type}
    >
      <BoxMedia>
        <CardStar backgroundColor={"#fff"} onClick={() => {
           setIsClicked(!isClicked)
        }} isClicked={isClicked}>
          <AiFillStar size={icon.size} color={props.review?.rating > 0 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.review?.rating > 1 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.review?.rating > 2 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.review?.rating > 3 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.review?.rating > 4 ? props.color_star_active : props.color_star_inactive} />

          <CardInfo backgroundColor={"#fff"} isClicked={isClicked}>
            <div className="client-name-lily">
              <span style={{ display: "flex", color: "config.data.config.font_color_primary", alignItems: "center" }}>
                {props.review.name}
                <BadgeCheck>
                  {props.review?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={props.color_verified} />}
                  {/* <label>{"translation.box.cardsQuestions.compraVerificada"}</label> */}
                </BadgeCheck>
              </span>
              <h5 style={{ color: "config.data.config.font_color_primary" }} >
                {/* {"config?.data?.config?.visible_date_review" == 1 && new Date(props.review.created_at).toLocaleDateString('pt-BR')} */}
                01/01/2024
              </h5>
            </div>
            <BoxText>
              <label style={{ color: "config.data.config.font_color_primary" }}>
                {props.review?.message}
              </label>
            </BoxText>
          </CardInfo>
        </CardStar>
        <CardPlay id='abrir' tiktok={true}>
          <iframe
            src={`https://tiktok.com/embed/${props?.review?.media[0]?.url}`}
            style={{ border: "none", " box-shadow": "rgba(0, 0, 0, 0.15) 0px 12px 28px 0px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(255, 255, 255, 0.01) 0px 0px 0px 1px inset", width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '16px', }}
            allowfullscreen
            scrolling="no"
            allow="encrypted-media;"
          />
        </CardPlay>
      </BoxMedia>
    </Container>
  );
};

export default CardTiktokBK;
