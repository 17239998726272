import React, { useEffect, useState } from "react";
import { BadgeCheck, BoxInfor, BoxMedia, BoxText, CardInfo, CardPlay, CardStar, Container, TitleName, } from "./styled";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getVideoTiktok } from "./functionsTiktok";
import { FaPlay, FaYoutube } from "react-icons/fa";
import { FiCameraOff } from "react-icons/fi";
import { HiBadgeCheck } from "react-icons/hi";

//import { getTranslation } from "../../../../translations";

const CardInstaBK = (props) => {

  // useEffect(() => {
  //   console.log("props card insta", props, config)
  // }, [props])

  const [icon, setIcon] = useState(
    {
      size: 20,
      colorActive: "orange",
      colorInative: "gray",
    }
  )


  const [isClicked, setIsClicked] = useState(false);
  const [ziggeoApi, setZiggeoApi] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);

  const getThumbnail = (token) => {
    let response = ziggeoApi.videos.get(token);

    response.success(function (data) {
      setThumbnail(data.original_stream.embed_image_url);
    })
  }
  const getMedia = (media, type) => {
    if (media?.type == "tiktok") {
    }
    switch (type) {
      case "image":
        return (
          <CardPlay id='abrir'>
            <img style={{ objectFit: "cover" }} src={props.item?.media[0]?.url} id='abrir' />
          </CardPlay>
        )
      case "video":
        return (
          ziggeoApi && getThumbnail(media?.url),
          <CardPlay id='abrir'>
            {thumbnail ?
              <img src={thumbnail} id='abrir' /> :
              <FaPlay color="gray" size={35} id='abrir' />
            }
          </CardPlay>
        )
      case "tiktok":
        return (
          <CardPlay>

            <iframe
              src={`https://tiktok.com/embed/7114045612609260806`}
              style={{ width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '0px', }}
              allowfullscreen
              scrolling="no"
              allow="encrypted-media;"
            />
          </CardPlay>
        )

      case "instagram":
        return (
          <CardPlay card={"insta"} style={{ border: "none" }}>
            <iframe
              id="frame"
              width="320"
              height="568"
              scrolling="no"
              src={`https://www.instagram.com/p/${media?.url}/embed`}
              frameborder="0" />
          </CardPlay>
        )

      case "youtube":
        return (
          <CardPlay>
            <FaYoutube size={50} color={"gray"} id='abrir' />

          </CardPlay>
        )
      default:
        return (
          <CardPlay id='abrir'>
            <FiCameraOff size={50} color={"gray"} id='abrir' />
          </CardPlay>
        )
    }
  }

  const controlClick = () => {
    if (
      props.item?.media[0]?.type == "instagram" ||
      props.review?.media[0]?.type == "youtube" ||
      props.item?.media[0]?.type == "tiktok"
    ) {
      //singleReview(props.item?.id)
    }

  }

  return (
    <Container
      key={props.item?.id}
      mediaType={props.item?.media[0]?.type}
      //style={{ paddingTop: "40px" }}
      onClick={() => { controlClick() }}

    >
      <BoxMedia>
        <CardStar backgroundColor={"#fff"} onClick={() => { setIsClicked(!isClicked) }} isClicked={isClicked}>
          <AiFillStar size={icon.size} color={props.item.rating > 0 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.item.rating > 1 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.item.rating > 2 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.item.rating > 3 ? props.color_star_active : props.color_star_inactive} />
          <AiFillStar size={icon.size} color={props.item.rating > 4 ? props.color_star_active : props.color_star_inactive} />
          <CardInfo backgroundColor={"#fff"} isClicked={isClicked}>
            <div className="client-name-lily">
              <span style={{ display: "flex", justifyContent: "center", color: `${"config.fontColorPrimary"}` }}>
                {props.item.name}
                <BadgeCheck>
                  {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={props.color_verified} />}
                  {/* <label>{"translation.box.cardsQuestions.compraVerificada"}</label> */}
                </BadgeCheck>

              </span>
              <label style={{ color: `${"config.fontColorPrimary"}`, textAlign: "center", fontSize: "12px", fontWeight: "initial" }}>
                {/* {new Date(props.item.created_at).toLocaleDateString('pt-BR')} */}
                01/01/2024
              </label>
            </div>
            <BoxText>
              <label style={{ color: `${"config.fontColorPrimary"}` }}>{props.item.message}</label>
            </BoxText>
          </CardInfo>
        </CardStar>
        {/* {getMedia(props.item.media[0], props?.item?.media[0]?.type)} */}
        <CardPlay card={"insta"} style={{ border: "none" }}>
          <iframe
            style={{borderRadius: "16px", border: "none", " box-shadow": "rgba(0, 0, 0, 0.15) 0px 12px 28px 0px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(255, 255, 255, 0.01) 0px 0px 0px 1px inset", }}
            id="frame"
            width="100%"
            height="580"
            scrolling="no"
            src={`https://www.instagram.com/p/${props?.item?.media[0]?.url}/embed`}
            frameborder="0" />
        </CardPlay>
      </BoxMedia>

    </Container>
  );
};

export default CardInstaBK;
