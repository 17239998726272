import { useEffect } from "react";
import { BoxResponse, Container, InforQuestion, InforStore, Question } from "./styled";
import { PiArrowElbowDownRightThin } from "react-icons/pi";

const CardQuestionLargeBK = (props) => {

    useEffect(() => {
        console.log("prps", props)
    }, [props])

    const formatDate = (value) => {
        const dateStr = value;
        const date = new Date(dateStr);

        // Lista de meses para facilitar a formatação
        const meses = [
            'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
            'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
        ];

        // Extraindo o dia, mês e ano
        const dia = date.getDate();
        const mes = meses[date.getMonth()];
        const ano = date.getFullYear();

        //     // Montando a data formatada
        return `${dia} de ${mes} de ${ano}`;
    }

    return (
        <Container>
            <Question>
                {props.item?.message}
            </Question>
            <InforQuestion>
                Perguntado por <span style={{ fontWeight: "600", margin: "0px 4px" }}>{props.item?.name}</span> em <span style={{ fontWeight: "600", margin: "0px 4px" }} >{props.item.dateMessage}</span>
            </InforQuestion>
            <BoxResponse>
                <PiArrowElbowDownRightThin color="#595959" style={{ marginRight: "10px" }} />
                {props.item?.response?.length > 0 ? props.item?.response : "Ainda não respondido"}
            </BoxResponse>

            {props.item?.response?.length > 0 &&
                <InforStore>
                    {/* Respondido por Extra em <span style={{ fontWeight: "600", margin: "0px 4px" }} >29 de setembro de 2023</span> */}
                    {/* Respondido em <span style={{ fontWeight: "600", margin: "0px 4px" }} > {props.item.dateResponse}</span> */}
                    11/09/2024
                </InforStore>}
        </Container>
    )
}

export default CardQuestionLargeBK