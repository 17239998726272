import { Container, Infor, List, ListItems, Search, Title } from './styled'
import NuvemshopIcon from "../../../../assets/imgs/integrations/nuvemshop.svg";
import ShopifyIcon from "../../../../assets/imgs/integrations/shopify.svg";
import CartpandaIcon from "../../../../assets/imgs/integrations/cartpanda.svg";
import LojaintegradaIcon from "../../../../assets/imgs/integrations/loja_integrada.svg";
import HotmartIcon from "../../../../assets/imgs/integrations/hotmart.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CardNew from '../card-new';
import { platforms } from "../../pages/home-new/dataCards";
import { getTranslation } from '../../../../translations';

const ListIntegrations = () => {

    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)
    const dispatch = useDispatch()


    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    const [statusNuvemshop, setStatusNuvemshop] = useState(false)
    const [statusShopify, setStatusShopify] = useState(false)
    const [statusDropi, setStatusDropi] = useState(false)
    const [statusCustom, setStatusCustom] = useState(false)
    const [statusHotmart, setStatusHotmart] = useState(false)
    const [statusMontink, setStatusMontink] = useState(false)
    const [statusRastreio, setStatusRastreio] = useState(false)
    const [statusLandPage, setStatusLandPage] = useState(false)
    const [statusWoocommerce, setStatusWoocommerce] = useState(false)
    const [statusMercadoshop, setStatusMercadoshop] = useState(false)
    const [statusLojaIntegrada, setStatusLojaIntegrada] = useState(false)
    const [statusCartpanda, setStatusCartpanda] = useState(false)
    const [statusWix, setStatusWix] = useState(false)
    const [statusTray, setStatusTray] = useState(false)
    const [statusBagy, setStatusBagy] = useState(false)
    const [statusSak, setStatusSak] = useState(false)

    useEffect(() => {
        console.log("statusPlatforms", statusPlatforms)
    }, [statusPlatforms])

    useEffect(() => { dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" }) }, [])


    useEffect(() => {
        if (statusPlatforms.data.length > 0) {
            for (let item of statusPlatforms.data) {
                switch (item.platform) {
                    case "nuvemshop": setStatusNuvemshop(true)
                        break;
                    case "shopify": setStatusShopify(true)
                        break;
                    case "mercadoshop": setStatusMercadoshop(true)
                        break;
                    case "dropi": setStatusDropi(true)
                        break;
                    case "custom": setStatusCustom(true)
                        break;
                    case "hotmart": setStatusHotmart(true)
                        break;
                    case "montink": setStatusMontink(true)
                        break;
                    case "rastreio": setStatusRastreio(true)
                        break;
                    case "landpage": setStatusLandPage(true)
                        break;
                    case "loja_integrada": setStatusLojaIntegrada(true)
                        break;
                    case "cartpanda": setStatusCartpanda(true)
                        break;
                    case "wix": setStatusWix(true)
                        break;
                    case "tray": setStatusTray(true)
                        break;
                    case "bagy": setStatusBagy(true)
                        break;
                    case "woocommerce": setStatusWoocommerce(true)
                        break;
                    case "sak": setStatusSak(true)
                        break;
                    default: break;
                }
            }
        } else {
            setStatusNuvemshop(false)
            setStatusShopify(false)
            setStatusDropi(false)
            setStatusCustom(false)
            setStatusHotmart(false)
            setStatusMontink(false)
            setStatusRastreio(false)
            setStatusLandPage(false)
            setStatusLojaIntegrada(false)
            setStatusCartpanda(false)
            setStatusWix(false)
            setStatusTray(false)
            setStatusBagy(false)
            setStatusWoocommerce(false)
            setStatusSak(false)
            setStatusMercadoshop(false)
        }
    }, [statusPlatforms])

    return (
        <Container>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-base font-semibold leading-6 text-gray-900">{translation.sidebar.integracoes}</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            {translation.title.integracoes}
                        </p>
                    </div>
                </div>
            </div>
            {/* <Search>
                pesquisar
            </Search> */}
            <List>


                {/* <Title>Escolha uma plataforma</Title>
                <Infor>É necessário realizar a integração para utilizar os recursos do app.</Infor> */}
                <ListItems>
                    <CardNew item={platforms.nuvemshop} install={statusNuvemshop} />
                    <CardNew item={platforms.shopify} install={statusShopify} />
                    <CardNew item={platforms.cartpanda} install={statusCartpanda} />
                    <CardNew item={platforms.loja_integrada} install={statusLojaIntegrada} />
                    <CardNew item={platforms.bagy} install={statusBagy} />

                    {/* <CardNew item={platforms.mercadoshop} /> */}


                    <CardNew item={platforms.wix} install={statusWix} />
                    <CardNew item={platforms.montink} empreender={true} install={statusMontink} />
                    <CardNew item={platforms.hotmart} install={statusHotmart} />
                    <CardNew item={platforms.landpage} empreender={true} install={statusLandPage} />
                    <CardNew item={platforms.dropi} empreender={true} install={statusDropi} />
                    <CardNew item={platforms.sak} empreender={true} install={statusSak} />
                    <CardNew item={platforms.rastreio} empreender={true} install={statusRastreio} />
                    <CardNew item={platforms.custom} install={statusCustom} />
                    <CardNew item={platforms.shopee} install={false} />




                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 40077 ?
                        <CardNew item={platforms.tray} install={statusTray} /> : <></>}

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 37727 ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 39043 ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 39754 ||
                        JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id == 39131 ?
                        <CardNew item={platforms.mercadoshop} install={statusMercadoshop} /> : <></>}

                    {/* <Item>
                        <img src={NuvemshopIcon} />
                        <span>
                            <p>Nuvemshop  </p>
                            <label>Crie sua loja na NuvemShop e comece a vender</label>
                        </span>
                        <Action>Conectar</Action>
                    </Item>

                    <Item>
                        <img src={ShopifyIcon} />
                        <span>
                            <p>Shopify  </p>
                            <label>Crie sua loja na NuvemShop e comece a vender</label>
                        </span>
                        <Action>Conectar</Action>
                    </Item>

                    <Item>
                        <img src={LojaintegradaIcon} />
                        <span>
                            <p>Loja Integrada  </p>
                            <label>Crie sua loja na NuvemShop e comece a vender</label>
                        </span>
                        <Action>Conectar</Action>
                    </Item>

                    <Item>
                        <img src={CartpandaIcon} />
                        <span>
                            <p>Cartpanda  </p>
                            <label>Crie sua loja na NuvemShop e comece a vender</label>
                        </span>
                        <Action>Conectar</Action>
                    </Item>

                    <Item>
                        <img src={HotmartIcon} />
                        <span>
                            <p>Hotmart  </p>
                            <label>Crie sua loja na NuvemShop e comece a vender</label>
                        </span>
                        <Action>Conectar</Action>
                    </Item> */}

                </ListItems>
            </List>
        </Container>
    )
}

export default ListIntegrations