import { TitleModal, BodyModal, ItemModal, Option } from "./styled";
import Modal from 'react-modal';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { AiOutlineLoading, AiFillCheckCircle, AiFillCaretRight } from "react-icons/ai";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { MdError } from "react-icons/md";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { platforms } from "../../pages/home/dataCards";
import { BsTools } from "react-icons/bs";
import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"

    }
};

const ModalComponent = () => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const statusSync = useSelector(state => state.reducerSync)
    const statusIntegration = useSelector(state => state.reducerIntegration)
    const controlPlatform = useSelector(state => state.reducerControlPlatform)
    const controlSyncProducts = useSelector(state => state.reducerControlSyncProducts)

    const dispatch = useDispatch()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const getIconIntegration = () => {
        if (!statusIntegration.loading && !statusIntegration.error && statusIntegration.data.length == 0) {
            //return <AiFillCaretRight size={20} color={"gray"} />
            return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
        } else {
            if (statusIntegration.loading === true) return <AiOutlineLoading size={20} color={"green"} />
            else {
                if (statusIntegration.error === true) return <MdError size={20} color={"red"} />
                else return <AiFillCheckCircle size={20} color={"green"} />
            }
        }
    }

    const getIconSync = () => {
        if (!statusSync.loading && !statusSync.error && statusSync.data.length == 0) {
            //return <AiFillCaretRight size={20} color={"gray"} />
            return <RiCheckboxBlankCircleFill size={12} color={"gray"} />
        } else {
            if (statusSync.loading == true) return <AiOutlineLoading size={20} color={"green"} />
            else {
                if (statusSync.error === true) return <MdError size={20} color={"red"} />
                else return <AiFillCheckCircle size={20} color={"green"} />
            }
        }
    }

    // useEffect(() => {
    //     if (controlPlatform.data.typeIntegration) {
    //         setModalIsOpen(true)
    //         if (controlPlatform.data.typeIntegration == "shopify") {
    //             controlPlatform.data.store = controlPlatform.data.shop
    //             controlPlatform.data.code = controlPlatform.data.code.split("&")[0]
    //         } else if (controlPlatform.data.typeIntegration == "custom") {
    //             controlPlatform.data.store = controlPlatform.data.url
    //         }
    //         dispatch({ type: "SAGA_INTEGRATE_INTEGRATION", payload: controlPlatform.data })
    //     }
    // }, [])

    useEffect(() => {


        if (controlPlatform.data.typeIntegration == "error") {

        } else if (controlPlatform.data.typeIntegration) {
            setModalIsOpen(true)
            if (controlPlatform.data.typeIntegration == "shopify") {
                //controlPlatform.data.store = controlPlatform.data.shop
                // controlPlatform.data.code = controlPlatform.data.code.split("&")[0]
            } else if (controlPlatform.data.typeIntegration == "custom") {
                controlPlatform.data.store = controlPlatform.data.url
            }
            dispatch({ type: "SAGA_INTEGRATE_INTEGRATION", payload: controlPlatform.data })
        }
    }, [])

    useEffect(() => {

        if (statusIntegration.error || statusSync.error) {
            setModalIsOpen(false)
            toast.error(statusIntegration.data?.description)
            //statusIntegration.data.length == 0 ?
            //toast.error("Erro na integração.", { onClose: setModalIsOpen(false), toastId: 0 }) :
            //toast.error(statusIntegration.data?.description + ` (${"mail"})`, { onClose: setModalIsOpen(false), toastId: 0 })
        } else if (controlPlatform.data?.typeIntegration == "sak" && statusIntegration.data.length != 0) {
            toast.success(`${controlPlatform.data.typeIntegration}  ${translation.integrations.modal.integradaSucesso}`, { onClose: setModalIsOpen(false), toastId: 0 })
        } else if (statusIntegration.data.length != 0 && statusSync.data.length != 0) {
            //toast.success(`${controlPlatform.data.typeIntegration == "custom" ? translation.integrations.modal.integracaoManual : controlPlatform.data.typeIntegration == "Nuvemshop" ? translation.integrations.nuvemshop : controlPlatform.data.typeIntegration}  ${translation.integrations.modal.integradaSucesso}`, { onClose: setModalIsOpen(false), toastId: 0 })
            console.log("teste integração - statusIntegration", statusIntegration)
            console.log("teste integração - statusSync", statusSync)
            setModalIsOpen(false)
            toast.success(statusIntegration?.data?.description)
            toast.success(statusSync?.data?.description)

        }
        // else if (controlPlatform.data.typeIntegration == "custom" && statusIntegration.data.length != 0) {
        //     toast.success(`${controlPlatform.data.typeIntegration} integrada com sucesso.`, { onClose: setModalIsOpen(false), toastId: 0 })
        // }
    }, [statusIntegration, statusSync])

    return (
        <Modal
            isOpen={modalIsOpen}
            //isOpen={true}
            //onRequestClose={() => setModalIsOpen(false)}
            style={customStyles}
            ariaHideApp={false}
        >

            <TitleModal>
                {/* {platforms[controlPlatform.data?.typeIntegration]?.title === "Custom" ?
                    <BsTools size={30} color={"#FE852B"} /> :
                    < img src={platforms[controlPlatform.data?.typeIntegration]?.image} height={38} />}

                <label>{platforms[controlPlatform.data?.typeIntegration]?.title == "Custom" ? "Integração Manual" : platforms[controlPlatform.data?.typeIntegration]?.title} </label> */}

                {platforms[controlPlatform.data?.typeIntegration]?.title === "Custom" ?
                    <BsTools size={25} color={"#FE852B"} /> :
                    <span>
                        < img src={platforms[controlPlatform.data?.typeIntegration]?.image} />
                    </span>}
                <label>{platforms[controlPlatform.data?.typeIntegration]?.title == "Custom" ? translation.integrations.modal.integracaoManual : platforms[controlPlatform.data?.typeIntegration]?.title} </label>
            </TitleModal>
            <BodyModal>
                <Option>
                    <label>{translation.integrations.modal.acao}</label>
                    <label>{translation.integrations.modal.progresso}</label>
                    <div>{translation.integrations.modal.feedback}</div>
                </Option>
                <Option loading={statusIntegration.loading}>
                    <label>{translation.integrations.modal.integracao}</label>
                    <label>{translation.integrations.modal.de}</label>
                    <div>
                        <span>
                            {getIconIntegration()}
                        </span>
                    </div>
                </Option>
                {
                    platforms[controlPlatform.data?.typeIntegration]?.title != "Sak" &&
                    <Option loading={statusSync.loading}>
                        <label>{translation.integrations.modal.sincronizacaoProdutos}</label>
                        <label>{controlSyncProducts.qtdSuccess} {translation.integrations.modal.de2} {controlSyncProducts.qtdTotal}</label>
                        <div>
                            <span>
                                {getIconSync()}
                            </span>
                        </div>
                    </Option>
                }

            </BodyModal>

            {/* <TitleModal>
                <img src={platforms[controlPlatform.data?.typeIntegration]?.image} height={38} />
                <label>{platforms[controlPlatform.data?.typeIntegration]?.title == "Custom" ? "Site" : platforms[controlPlatform.data?.typeIntegration]?.title} </label>
            </TitleModal>
            <BodyModal>

                <ItemModal loading={statusIntegration.loading}>
                    <span>{getIconIntegration()}</span>
                    <label>Integração</label>
                </ItemModal>

                <ItemModal loading={statusSync.loading}>
                    <span>{getIconSync()}</span>
                    <label>Sinconização de Produtos</label>
                </ItemModal>

            </BodyModal> */}
        </Modal>
    )
}

export default ModalComponent
