
import styled from "styled-components";

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    gap: 16px;
    height: max-content;
    break-inside: avoid;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 28px 0px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(255, 255, 255, 0.01) 0px 0px 0px 1px inset; /* box-shadow */
    padding-bottom: 20px;
    /* max-width: 315px;
    height: 500px;
    flex-direction: column;
    background-color: violet;
    overflow: hidden; */
    
`
export const CardPlay = styled.div`
  width: 150px;
  height: 100px ;
  display: flex ;
  justify-content: center ;
  align-items: center ;
  border: 2px solid gray ;
  background-color: ${props => props.theme.background_color_primary} ;
  border-radius: 5px ;
`

export const BoxImage = styled.div`
    display: flex;
    //width: 470px;
    //height: 400px;
    position: relative;
    border-radius: 16px;

    img{
        width: 100%;
        //height: 400px;
        object-fit: cover;
        border-radius: 16px !important;
    }

    div{
        position: relative;
        border-radius: 16px;
        img{
            width: 100%;
            //height: 400px;
            object-fit: cover;
            border-radius: 16px !important;
        }
        span{
            background-color: #00000088;
            min-width: 100%;
            min-height: 100%;
            position: absolute;
            top: 0px;
            border-radius: 16px;
        }
    }

   
    

    

    svg{
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        
    }

    
    
`

export const Stars = styled.div`
    display: flex;
    gap: 3px;
    padding: 0px 20px;
    
`

export const Name = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #1f2937;
    font-size: 18px;
    font-weight: 700;
    padding: 0px 20px;
    align-items: center;
    
`

export const CardText = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    padding: 0px 20px;
    
`

export const More = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #00000080;
    font-size: 18px;
    padding: 0px 20px;
`


export const Comment = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #000000b3;
    font-size: 18px;
    display: flex;
    padding: 0px 20px;
    line-height: 1.8;
`


// export const Image = styled.div`
//   width: 100%;
//   height: 300px;
//   background-image: url(${(props) => props.image});
//   background-size: cover;
//   background-position: center;
//   position: relative;
//   overflow: hidden;
// `;


// export const Overlay = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: ${(props) => props.percentage}%;
//   height: 100%;
//   background-color: ${(props) => props.color || "rgba(0, 0, 0, 0.5)"};
//   pointer-events: none;
// `;

