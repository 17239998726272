import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    //min-width: 100%;
    //background-color: #ECECED;
    background-color: transparent;
    align-items: center;
    //background-color: red;
    margin: 0px;
`;


export const Contents = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: calc(100% - 0px);
    //background-color:  #ECECED;
    background-color: transparent;
    max-width: 1400px;
    padding: 20px;
    gap: 20px;
`;


export const BoxResumeActionFilter = styled.div`
    display: flex;
    height: 200px;
    //background-color: green;
    justify-content: space-between;
    //padding: 20px;

    @media(max-width: 950px) {
        //background-color: red ;
        flex-direction: column;
        height: max-content;
        gap: 20px;
    }

`



export const BoxActionFilter = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media(max-width: 950px) {
        //background-color: red ;
        flex-direction: row;
        align-items: center;
    }

    @media(max-width: 650px) {
        //background-color: green ;
        flex-direction: column;

    }
    
`

export const CustomSelect = styled.div`
    position: relative;
    display: inline-block;

    ::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #000; /* Cor da seta */
        pointer-events: none;
    }
`


export const SelectFilter = styled.select`
    display: flex;
    height: 48px;
    padding: 0px 24px;
    border-radius: 8px;
    font-size: 20px;
    font-family: Rubik, sans-serif;
    width: 100%;
    outline: none;
    //border: 1px solid #4c4c4c;
    background-color: #fff;
    cursor: pointer;

    appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

    @media(max-width: 650px) {
        width: calc(100% - 20px);

    }
`

export const ActionReview = styled.label`
    display: flex;
    background-color: #000000e6;
    color: #fff;
    padding: 0px 24px;
    height: 56px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Rubik, sans-serif;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffffff; /* hover:tw-text-white */
    }

    @media(max-width: 650px) {
        width: calc(100% - 70px);

    }
`

export const BoxResume = styled.div`
    display: flex;
    //background-color: red;
    height: 200px;
    gap: 20px;

    @media(max-width: 650px) {
        //background-color: red ;
        flex-direction: column;
        height: max-content;
        
    }
    
`

export const QtdReview = styled.div`
    display: flex;
    //background-color: yellow;
    width: 170px;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;

    @media(max-width: 650px) {
        //background-color: purple ;
        width: 100%;
        
    }

    label{
        font-size: 76px;
        color: #374151;
        font-family: Rubik, sans-serif;
        font-weight: 600;
    }

    p{
        font-size: 20px;
        padding: 0px;
        margin: 0px;
        color: #6b7280;
        font-family: Rubik, sans-serif;
    }
    span{
        display: flex;
        gap: 5px;
    }
    
`

export const InforReviews = styled.div`
    display: flex;
    //background-color: violet;
    flex-direction: column;
    justify-content: center;

    @media(max-width: 950px) {
        //background-color: purple ;
        width: 100%;
        
    }
    
`
export const InforReviewsLine = styled.div`
    display: flex;
    //background-color: blue;
    align-items: center;
    height: 36px;
    gap: 12px;

       

    span{
        min-width: 300px;
        min-height: 10px;
        height: 10px;
        width: 300px;
        background-color: ${props => props.colorInactive};
        position: relative;


        @media(max-width: 950px) {
            //background-color: green ;
            width: 100%;
            
        }

        div{
            position: absolute;
            background-color: ${props => props.colorActive};
            height: 10px;
            width: ${props => props.porcentage};
        }
    }

    label{
        font-size: 20px;
        color: #6b7280;
        font-weight: 400;
        font-family: Rubik, sans-serif;
        //background-color: red;
        min-width: 20px;
        text-align: right;
    }

    p{
        font-size: 20px;
        margin: 0px;
        padding: 0px;
        color: #6b7280;
        font-weight: 400;
        font-family: Rubik, sans-serif;
    }
`
export const Divider = styled.div`
    display: flex;
    background-color: #f3f4f6;
    width: 2px;
    min-width: 2px;
    margin: 0px 20px;

    @media(max-width: 650px) {
        display: none;
    }
    
`
export const BoxListReviews = styled.div`

    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(315px, 1fr));
    grid-gap: 16px;

    align-items: stretch; Estica os itens para terem a mesma altura */
    //background-color: red;
    column-count: 3;
  



    @media (min-width: 360px) {
        column-count: 1;
        //column-gap: 20px;
    }

    @media (min-width: 800px) {
        column-count: 1;
        column-gap: 20px;
    }

    @media (min-width: 1024px) {
        column-count: 1;
        column-gap: 20px;
    }

    @media (min-width: 1400px) {
        column-count: 2;
        column-gap: 20px;
    }

    @media (min-width: 1800px) {
        column-count: 3;
        column-gap: 20px;
    }
`
export const ItemStar = styled.div`
    display: flex;
    position: relative;
    overflow: hidden;

    svg{
        color: ${props => props.colorInactive};
    }

    svg:nth-child(2){
        position: absolute;
        top: 0px;
        left: 0px;
        color: ${props => props.colorActive};
        clip-path: ${props => `polygon(0 0, ${props.porcentage} 0, ${props.porcentage} 100%, 0 100%)`} ;
    }
`
export const ButtonMore = styled.label`
    display: flex;
    background-color: transparent;
    color: #000;
    padding: 10px 20px;
    border: 1px solid #000;
    font-size: 20px;
    width: max-content;
    border-radius: 8px;
    font-family: Rubik, sans-serif;
    font-weight: 600;
    cursor: pointer;

    :hover{
        background-color: #000;
        color: #FFF;
    }
`

export const BoxAbas = styled.div`
    display: flex;
    height: 80px;
    background-color: ${props => props.background};
    align-items: center;
    padding: 0px 10px;
    gap: 10px;
    margin-bottom: 20px;

    @media(max-width: 500px) {

    }
`

export const ItemAba = styled.label`
    display: flex;
    //background-color: ${props => props.active ? props.background : "transparent"};
    background-color: ${props => props.active == false ? "transparent" : props.backgroundItem ? props.backgroundItem : props.background};
    flex: 1;
    height: 60px;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.5s;
    color: #808080;

    @media(max-width: 500px) {
        font-size: 18px;
    }
`

export const BoxQuestionsNew = styled.div`
    display: flex;
    gap: 20px;
    //background-color: red;

    //flex-direction: column;
`

export const BoxAction = styled.div`
    display: flex;
    //background-color: yellow;
    width: 320px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`

export const ActionQuetion = styled.label`
    display: flex;

    height: 56px;
    border-radius: 8px;
    background-color: #000000e6;
    color: #fff;
    align-items: center;
    justify-content: center;
    padding: 0px 24px;
    font-family: Rubik, sans-serif;
    font-size: 20px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffffff; /* hover:tw-text-white */
    }

`

export const QuestionInfor = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    font-weight: 600;
    color: #595959;
    font-size: 16px;

`

export const BoxQuestionsList = styled.div`
    display: flex;
    flex: 1;
    //background-color: purple;
    flex-direction: column;
    gap: 20px;
`
export const CardQuestion = styled.div`
    display: flex;
    //background-color: red;
`
