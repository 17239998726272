import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 28px 0px, rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(255, 255, 255, 0.01) 0px 0px 0px 1px inset; /* box-shadow */
    padding-bottom: 20px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    gap: 16px;
    padding: 20px;
`

export const Question = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    font-weight: 600;
    color: #595959;
    font-size: 16px;
`

export const InforQuestion = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #595959;
    font-size: 12px;
    font-weight: 400;
`

export const BoxResponse = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    //background-color: #F2F2F2;
    //padding: 20px;
    color: #595959;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
`
export const InforStore = styled.label`
    display: flex;
    font-family: Rubik, sans-serif;
    color: #595959;
    font-size: 12px;
    font-weight: 400;
    padding-left: 10px;
`