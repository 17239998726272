import { Container, ButtonCustom, Close, Title, Item, ItemTitle, InputCustomShopify, Code, OptionsLabel, Box, BoxIfrane } from "./styled";
import Modal from 'react-modal';
import { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import Alert from "../../../../components/Alert";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BiCopy } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { useEffect } from "react";
import { AiFillYoutube } from "react-icons/ai";
import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
        overflow: "auto",
        maxHeight: "90vh",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalIntegration = (props) => {

    const [inputStore, setInputStore] = useState("")
    const [inputUrlSite, setInputUrlSite] = useState("")
    const [inputClientId, setInputClientId] = useState("")
    const [inputClientSecret, setInputClientSecret] = useState("")
    const [inputBasic, setInputBasic] = useState("")
    const [tokenMontink, setTokenMontink] = useState("")
    const [chaveLojaIntegrada, setChaveLojaIntegrada] = useState("")
    const [tokenCartpanda, setTokenCartpanda] = useState("")
    const [tokenSak, setTokenSak] = useState("")
    const [webhookSak, setWebhookSak] = useState("")

    const [trayCode, setTrayCode] = useState("")
    const [trayUrl, setTrayUrl] = useState("")

    const [bagyToken, setBagyToken] = useState("")

    //shopify

    const [shopifyToken, setShopifyToken] = useState("")
    const [shopifyStore, setShopifyStore] = useState("")
    const [shopifyKey, setShopifyKey] = useState("")
    const [shopifyKeySecret, setShopifyKeySecret] = useState("")

    const history = useHistory();

    const webhook = `${process.env.REACT_APP_API_URL}/webhook/hotmart?store=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`

    const iconVideo = { color: "red", size: 25 }

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const getBodyPlatform = (platform) => {

        switch (platform.title) {
            case "Nuvemshop":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? "Nuvemshop" : "Tiendanube"}</strong>
                        </Title>
                        <ItemTitle>
                            {platform.body.title}
                            {/* <AiFillYoutube onClick={() => { alert("teste") }} style={{ cursor: "pointer" }} color={iconVideo.color} size={iconVideo.size} /> */}
                        </ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/4j6SIBG9ckM" title="Integração Lily Reviews com Nuvemshop" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        {platform.body.steps.map((item) => (<Item key={Math.random()}>{item}</Item>))}
                        <Alert margin="0px" padding="10px" background="red" >
                            <label style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}.</label>
                        </Alert>
                        <ButtonCustom onClick={() => { window.location = platform.action }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>

                        {/* <Box>
                            <iframe src="https://www.youtube.com/embed/4j6SIBG9ckM" title="Integração Lily Reviews com Nuvemshop" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                            <span>
                                <Alert margin="15px 0px 0px 0px" padding="0px 10px" background="red" >
                                    <small style={{ fontSize: "11px", height: "80px", display: "flex", alignItems: "center", textAlign: "center" }}>Assista o vídeo tutorial e não deslogue do Lily Reviews durante o processo de integração.</small>
                                </Alert>
                                <ButtonCustom onClick={() => { window.location = platform.action }}>Conectar</ButtonCustom>
                            </span>
                        </Box> */}
                    </>
                )
            case "Tray":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"url"}
                            value={trayUrl}
                            onChange={(e) => { setTrayUrl(e.target.value) }}
                        />
                         {/*<Item>{platform.body.steps[1]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={translation.integrations.modalIntegration.codigo}
                            value={trayCode}
                            onChange={(e) => { setTrayCode(e.target.value) }}
                        />
                        <Item>{platform.body.steps[2]}</Item>
                        <Item>{platform.body.steps[3]}</Item> */}
                        {/* <Alert marginBottom="0px" padding="0px 10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            trayUrl == "" || trayCode == "" ?
                                toast.warning("Preencha os dados.", { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/tray',
                                    search: `?trayCode=${trayCode}&trayUrl=${trayUrl}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom> */}
                        <Alert margin="0px" padding="10px" background="red" >
                            <label style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}.</label>
                        </Alert>
                        <ButtonCustom onClick={() => { window.location = `${trayUrl}/auth.php?response_type=code&consumer_key=c97019143e06fa48e90db88d7f1558d675c378ed0221c442fc8cfe1e11e4aeda&callback=https://app.lily.com.br/integracoes/tray` }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )
            case "Mercadoshop":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? "Mercadoshop" : "Mercadoshop"}</strong>
                        </Title>
                        <ItemTitle>
                            {platform.body.title}
                            {/* <AiFillYoutube onClick={() => { alert("teste") }} style={{ cursor: "pointer" }} color={iconVideo.color} size={iconVideo.size} /> */}
                        </ItemTitle>
                        {/* <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/4j6SIBG9ckM" title="Integração Lily Reviews com Nuvemshop" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane> */}
                        {platform.body.steps.map((item) => (<Item key={Math.random()}>{item}</Item>))}
                        <Alert margin="0px" padding="10px" background="red" >
                            <label style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}.</label>
                        </Alert>
                        <ButtonCustom onClick={() => { window.location = platform.action }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>

                        {/* <Box>
                                <iframe src="https://www.youtube.com/embed/4j6SIBG9ckM" title="Integração Lily Reviews com Nuvemshop" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                                <span>
                                    <Alert margin="15px 0px 0px 0px" padding="0px 10px" background="red" >
                                        <small style={{ fontSize: "11px", height: "80px", display: "flex", alignItems: "center", textAlign: "center" }}>Assista o vídeo tutorial e não deslogue do Lily Reviews durante o processo de integração.</small>
                                    </Alert>
                                    <ButtonCustom onClick={() => { window.location = platform.action }}>Conectar</ButtonCustom>
                                </span>
                            </Box> */}
                    </>
                )
            case "Shopify":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title} </ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/Ve4aHQ3y1-s" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]} <FiExternalLink onClick={() => { window.open("https://shopify.com/admin/settings/apps/development") }} size={20} color={"gray"} style={{ cursor: "pointer", marginBottom: "5px" }} /></Item>
                        <Item>{platform.body.steps[1]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={translation.integrations.modalIntegration.minhaloja + ".myshopify.com"}
                            value={shopifyStore}
                            autoFocus
                            onChange={(e) => { setShopifyStore(e.target.value) }}
                        />
                        <Item>{platform.body.steps[2]}</Item>
                        <OptionsLabel>
                            <label>{translation.integrations.modalIntegration.produtos}</label>
                            <label>{translation.integrations.modalIntegration.listagensProdutos}</label>
                            <label>{translation.integrations.modalIntegration.pedidos}</label>
                            <label>{translation.integrations.modalIntegration.clientes}</label>
                            <label>{translation.integrations.modalIntegration.descontos}</label>
                            <label>{translation.integrations.modalIntegration.tagsScript}</label>
                            <label>{translation.integrations.modalIntegration.rascunhosPedido}</label>
                            <label>{translation.integrations.modalIntegration.servicosPedidos}</label>
                        </OptionsLabel>
                        <Item>{platform.body.steps[3]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"Token"}
                            value={shopifyToken}
                            onChange={(e) => { setShopifyToken(e.target.value) }}
                        />
                        <Item>{platform.body.steps[4]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={translation.integrations.modalIntegration.chave}
                            value={shopifyKey}
                            onChange={(e) => { setShopifyKey(e.target.value) }}
                        />
                        <Item>{platform.body.steps[5]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={translation.integrations.modalIntegration.chaveSecreta}
                            value={shopifyKeySecret}
                            onChange={(e) => { setShopifyKeySecret(e.target.value) }}
                        />
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            shopifyStore == "" || shopifyToken == "" || shopifyKey == "" || shopifyKeySecret == "" ?
                                toast.warning("Preencha todos os dados.", { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/shopify',
                                    search: `?urlShopify=${shopifyStore.replace("https://", "").replace("http://", "")}&tokenShopify=${shopifyToken}&keyShopify=${shopifyKey}&keySecretShopify=${shopifyKeySecret}`
                                })
                            //window.location = platform.action.replace("#loja_shopify#", inputStore[inputStore.length - 1] == "/" ? inputStore.substring(0, inputStore.length - 1).replace("https://", "").replace(".myshopify.com", "") : inputStore.replace("https://", "").replace(".myshopify.com", ""))
                        }}>
                            {translation.integrations.modalIntegration.conectar}
                        </ButtonCustom>

                    </>
                )
            // case "Shopify":
            //     return (
            //         <>
            //             <Title>
            //                 <strong>Configuração {platform.title}</strong>
            //             </Title>
            //             <ItemTitle>{platform.body.title}</ItemTitle>
            //             <Item>{platform.body.steps[0]}</Item>
            //             <Item>{platform.body.steps[1]}</Item>
            //             <InputCustomShopify
            //                 type={"text"}
            //                 placeholder={"minhaloja"}
            //                 value={inputStore}
            //                 autoFocus
            //                 onChange={(e) => { setInputStore(e.target.value) }}
            //             />
            //             <Item>{platform.body.steps[2]}</Item>
            //             <Item>{platform.body.steps[3]}</Item>
            //             <Alert marginBottom="0px" padding="0px 10px" background="red" >
            //                 <small style={{ fontSize: "11px" }}>Não deslogue do Lily Reviews durante o processo de integração.</small>
            //             </Alert>
            //             <ButtonCustom onClick={() => {
            //                 inputStore == "" ?
            //                     toast.warning("Endereço da loja não pode ser vazio.", { toastId: 0 }) :
            //                     window.location = platform.action.replace("#loja_shopify#", inputStore[inputStore.length - 1] == "/" ? inputStore.substring(0, inputStore.length - 1).replace("https://", "").replace(".myshopify.com", "") : inputStore.replace("https://", "").replace(".myshopify.com", ""))
            //             }}>Conectar</ButtonCustom>
            //         </>
            //     )
            case "Dropi":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/J81i6BfJcyA" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        {platform.body.steps.map((item) => (<Item key={Math.random()}>{item}</Item>))}
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => { window.location = platform.action }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )
            case "Custom":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title == "Custom" ? translation.integrations.modalIntegration.integracaoManual : platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? translation.integrations.modalIntegration.integracaoManual : platform.body.title}</ItemTitle>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"meusite.com.br"}
                            value={inputUrlSite}
                            autoFocus
                            onChange={(e) => { setInputUrlSite(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="0px" padding="20px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            inputUrlSite == "" ?
                                toast.warning(translation.integrations.modalIntegration.naoVazio, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/custom',
                                    search: `?url=${inputUrlSite}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )
            case "Land Page":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/LyC13rJzqP4" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"meusite.com.br"}
                            value={inputUrlSite}
                            autoFocus
                            onChange={(e) => { setInputUrlSite(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            inputUrlSite == "" ?
                                toast.warning(translation.integrations.modalIntegration.landPage, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/landpage',
                                    search: `?url=${inputUrlSite}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>

                    </>
                )
            case "Woo Commerce":
                return (
                    <>
                        <Title>
                            <strong>Configuração {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/LyC13rJzqP4" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"meusite.com.br"}
                            value={inputUrlSite}
                            autoFocus
                            onChange={(e) => { setInputUrlSite(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>Não deslogue do Lily Reviews durante o processo de integração.</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            inputUrlSite == "" ?
                                toast.warning("Url do Woo Commerce não pode ser vazio.", { toastId: 0 }) :
                                window.location = `${inputUrlSite.includes("https://") ? inputUrlSite : "https://" + inputUrlSite}/wc-auth/v1/authorize?app_name=lily&scope=read_write&user_id=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.id}&return_url=${process.env.REACT_APP_URL}/integracoes/woocommerce?url=${inputUrlSite.includes("https://") ? inputUrlSite : "https://" + inputUrlSite}&callback_url=${process.env.REACT_APP_API_URL}/integrations/woocommerce`
                        }}>Conectar</ButtonCustom>

                    </>
                )
            case "Montink":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/3z8C1wSafzQ" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"token"}
                            value={tokenMontink}
                            onChange={(e) => { setTokenMontink(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <Alert margin="0px" padding="20px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            tokenMontink == "" ?
                                toast.warning(translation.integrations.modalIntegration.tokenLoja, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/montink',
                                    search: `?tokenMontink=${tokenMontink}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )
            case "Loja Integrada":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title == "Custom" ? "Site" : platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/BHsbXwpj7nM" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"meusite.com.br"}
                            value={inputUrlSite}
                            autoFocus
                            onChange={(e) => { setInputUrlSite(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={translation.integrations.modalIntegration.chave}
                            value={chaveLojaIntegrada}
                            onChange={(e) => { setChaveLojaIntegrada(e.target.value) }}
                        />
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            chaveLojaIntegrada == "" || inputUrlSite.length == 0 ?
                                toast.warning(translation.integrations.modalIntegration.preenchaOsdados, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/loja_integrada',
                                    search: `?chaveLojaIntegrada=${chaveLojaIntegrada}?url=${inputUrlSite}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>


                    </>
                )

            case "Wix":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuradacao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title}</ItemTitle>
                        {platform.body.steps.map((item) => (<Item key={Math.random()}>{item}</Item>))}
                        <Alert margin="0px" padding="20px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => { window.location = platform.action }}>
                            {translation.integrations.modalIntegration.conectar}
                        </ButtonCustom>
                    </>
                )
            case "Hotmart":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title == "Custom" ? "Site" : platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/5QI9BrGLelU" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"meusite.com.br"}
                            value={inputUrlSite}
                            autoFocus
                            onChange={(e) => { setInputUrlSite(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <Item>{platform.body.steps[2]}</Item>
                        <Item>{platform.body.steps[3]}</Item>
                        <Item>{platform.body.steps[4]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"Client ID"}
                            value={inputClientId}

                            onChange={(e) => { setInputClientId(e.target.value) }}
                        />
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"Client Secret"}
                            value={inputClientSecret}

                            onChange={(e) => { setInputClientSecret(e.target.value) }}
                        />
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"Basic"}
                            value={inputBasic}

                            onChange={(e) => { setInputBasic(e.target.value) }}
                        />
                        <Item>{platform.body.steps[5]}</Item>
                        <Item>
                            <Code>
                                <label>{webhook}</label>
                                <BiCopy onClick={() => { copy(webhook) }} size={15} color={"gray"} />
                            </Code>
                        </Item>
                        <Item>{platform.body.steps[6]}</Item>
                        <Item>{platform.body.steps[7]}</Item>
                        <Item>{platform.body.steps[8]}</Item>
                        <Item>{platform.body.steps[9]}</Item>
                        <Item>{platform.body.steps[10]}</Item>
                        <Alert margin="0px" padding="20px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            inputClientId == "" || inputClientSecret == "" || inputBasic == "" || inputUrlSite == "" ?
                                toast.warning(translation.integrations.modalIntegration.necessarioPreencher, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/hotmart',
                                    search: `?store=${inputUrlSite}&client_id=${inputClientId}&client_secret=${inputClientSecret}&basic=${inputBasic}`
                                })
                            //alert("TESTE")
                        }}>Conectar</ButtonCustom>
                    </>
                )
            case "Cartpanda":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title == "Custom" ? "Site" : platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/n8nDYDc1OdE" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"https://sualoja.mycartpanda.com"}
                            value={inputUrlSite}
                            autoFocus
                            onChange={(e) => { setInputUrlSite(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"Token"}
                            value={tokenCartpanda}
                            onChange={(e) => { setTokenCartpanda(e.target.value) }}
                        />
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            tokenCartpanda == "" || inputUrlSite.length == 0 ?
                                toast.warning(translation.integrations.modalIntegration.preenchaOsdados, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/cartpanda',
                                    search: `?tokenCartpanda=${tokenCartpanda}?url=${inputUrlSite}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )

            case "Bagy":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/7ZpgCMz6sEI" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane>
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"token"}
                            value={bagyToken}
                            onChange={(e) => { setBagyToken(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert marginBottom="0px" padding="0px 10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            bagyToken == "" ?
                                toast.warning("Preencha os dados.", { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/bagy',
                                    search: `?bagyToken=${bagyToken}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )

            case "Sak":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title == "Custom" ? "Site" : platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title == "Custom" ? "Site" : platform.body.title}</ItemTitle>
                        {/* <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/n8nDYDc1OdE" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane> */}
                        <Item>{platform.body.steps[0]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"https://app.sak.com.br/webhook/get/lily/012345/lily"}
                            value={webhookSak}
                            autoFocus
                            onChange={(e) => { setWebhookSak(e.target.value) }}
                        />
                        <Item>{platform.body.steps[1]}</Item>
                        <InputCustomShopify
                            type={"text"}
                            placeholder={"Token"}
                            value={tokenSak}
                            onChange={(e) => { setTokenSak(e.target.value) }}
                        />
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                        </Alert>
                        <ButtonCustom onClick={() => {
                            tokenSak == "" ?
                                toast.warning(translation.integrations.modalIntegration.preenchaOsdados, { toastId: 0 }) :
                                history.push({
                                    pathname: '/integracoes/sak',
                                    search: `?tokenSak=${tokenSak}&webhookSak=${webhookSak}`
                                })
                        }}>{translation.integrations.modalIntegration.conectar}</ButtonCustom>
                    </>
                )

            case "Rastreio":
                return (
                    <>
                        <Title>
                            <strong>{translation.integrations.modalIntegration.configuracao} {platform.title == "Custom" ? "Site" : platform.title}</strong>
                        </Title>
                        <ItemTitle>{platform.body.title}</ItemTitle>
                        {/* <BoxIfrane>
                            <iframe src="https://www.youtube.com/embed/n8nDYDc1OdE" title="Lily Reviews" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                        </BoxIfrane> */}
                        <Item>{platform.body.steps[0]}</Item>
                        <Item>{platform.body.steps[1]}</Item>
                        <Item>{platform.body.steps[2]}</Item>
                        <Alert margin="20px 0px 0px 0px" padding="10px" background="red" >
                            <small style={{ fontSize: "11px" }}>
                                <small style={{ fontSize: "11px" }}>{translation.integrations.modalIntegration.naoDeslogue}</small>
                            </small>
                        </Alert>
                        <ButtonCustom onClick={() => { window.open('https://app.rastreio.net/configuracoes', '_blank') }}>
                            {translation.integrations.modalIntegration.conectar}
                        </ButtonCustom>


                    </>
                )

            default:
                return null
        }
    }

    const copy = (text) => {
        navigator.clipboard.writeText(text)
        toast.success(translation.integrations.modalIntegration.codigoCopiado)
    }



    return (
        <Modal
            isOpen={props.visible}
            onRequestClose={() => {
                props.setVisibleModal(false)
            }}
            style={customStyles}
            ariaHideApp={false}
        >
            <Container>
                <Close
                    onClick={() => {
                        props.setVisibleModal(false)
                    }}
                ><GrFormClose size={25} color={"#5e6278"} /></Close>
                {getBodyPlatform(props.platform)}
            </Container>
        </Modal>
    )
}

export default ModalIntegration
