import { Container } from './styled'
import { questions } from "../../pages/home/dataPreview";
import CardQuestion from "../card-question-preview"
import Card2Question from '../card2-question-preview';
import Card3Question from '../card3-question-preview';
import Card4Question from '../card4-question-preview';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const BoxReviews = (props) => {

    const config = useSelector(state => state.reducerControlConfig)


    

    const getCard = (card, item) => {
        switch (card) {
            case "modelo1":
                return <CardQuestion item={item} />
            case "modelo2":
                return <Card2Question item={item} />
            case "modelo3":
                return <Card3Question item={item} />
            case "modelo4":
                return <Card4Question item={item} />
            default:
                break;
        }
    }

    return (
        <Container bk={props.bk}>
            {questions.map((item) => {
                return (getCard(config.layout_question, item))
            })}
        </Container>
    )
}

export default BoxReviews