import sapato from "../../../../assets/imgs/icon-sapato.svg";

export const data = [
    {
        "name": "João Silva",
        "rating": 5,
        "message": "Camisa incrível! Material de alta qualidade e ótimo caimento.",
        "verified": true,
        "media": [
            { "type": "video", "url": "https://loja.empreender.com.br/cdn/shop/files/round-neck-t-shirt-mockup-of-a-woman-standing-in-a-customizable-setting-m2328-r-el2.png" }
        ]
    },
    {
        "name": "Juliana Reis",
        "rating": 4,
        "message": "A camisa é bonita, mas o tecido poderia ser um pouco mais leve.",
        "verified": true,
        "media": [
            { "type": "image", "url": "" }
        ]
    },
    {
        "name": "Mariana Costa",
        "rating": 5,
        "message": "Super confortável e perfeita para todas as ocasiões.",
        "verified": true,
        "media": [
            { "type": "image", "url": "https://loja.empreender.com.br/cdn/shop/files/transparent-t-shirt-mockup-featuring-a-woman-wearing-sunglasses-2813-el1.png" }
        ]
    },
    {
        "name": "Carlos Pereira",
        "rating": 5,
        "message": "Adorei o design e o tecido é bem resistente.",
        "verified": true,
        "media": [
            { "type": "image", "url": "https://loja.empreender.com.br/cdn/shop/files/scoop-neck-tee-mockup-of-a-woman-talking-on-the-phone-m2511-r-el2.png" }
        ]
    },
    {
        "name": "Ana Fernandes",
        "rating": 5,
        "message": "Muito satisfeita com a compra, vale cada centavo!",
        "verified": true,
        "media": [
            { "type": "youtube", "url": "j-vJcVcwoVU" }
        ]
    },
    {
        "name": "Pedro Santos",
        "rating": 5,
        "message": "Estilosa e leve, perfeita para o verão.",
        "verified": true,
        "media": [
            { "type": "image", "url": "" }
        ]
    },
    {
        "name": "Beatriz Almeida",
        "rating": 5,
        "message": "Cores vibrantes e não desbota na lavagem.",
        "verified": true,
        "media": [
            { "type": "tiktok", "url": "7114045612609260806" }
        ]
    },
    {
        "name": "Fernanda Lima",
        "rating": 5,
        "message": "Produto excelente, combina com tudo!",
        "verified": true,
        "media": [
            { "type": "image", "url": "" }
        ]
    },
    {
        "name": "Lucas Oliveira",
        "rating": 5,
        "message": "Melhor camisa que comprei nos últimos anos.",
        "verified": true,
        "media": [
            { "type": "image", "url": "https://loja.empreender.com.br/cdn/shop/files/cropped-face-mockup-featuring-a-man-wearing-a-t-shirt-and-posing-with-is-hands-in-his-pockets-m18513-r-el2_1.png" }
        ]
    },
    {
        "name": "Ricardo Mendes",
        "rating": 5,
        "message": "Ótima qualidade e acabamento impecável.",
        "verified": true,
        "media": [
            { "type": "instagram", "url": "C7ew1iBO1EJ" }
        ]
    },
    {
        "name": "Rafael Nunes",
        "rating": 4,
        "message": "Bom produto, mas achei o tamanho um pouco maior do que o esperado.",
        "verified": true,
        "media": [

        ]
    },
    {
        "name": "Sofia Martins",
        "rating": 5,
        "message": "Muito confortável e de fácil manutenção.",
        "verified": true,
        "media": [
            { "type": "image", "url": "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-of-a-serious-faced-girl-standing-in-a-studio-20844.png" }
        ]
    },
    {
        "name": "Gabriel Rocha",
        "rating": 5,
        "message": "Tecido respirável e durável, ideal para o dia a dia.",
        "verified": true,
        "media": [
            { "type": "image", "url": "https://loja.empreender.com.br/cdn/shop/files/t-shirt-mockup-featuring-a-muscled-man-in-a-studio-2976-el1.png" }

        ]
    },
    {
        "name": "Marcela Barbosa",
        "rating": 5,
        "message": "Produto excelente, recomendaria a todos.",
        "verified": true,
        "media": [
            { "type": "instagram", "url": "C7ew1iBO1EJ" }
        ]
    },
    {
        "name": "Renato Souza",
        "rating": 3,
        "message": "A qualidade é razoável, mas desbotou um pouco após algumas lavagens.",
        "verified": true,
        "media": []
    }
]